import {
  Container,
  Box,
  Typography,
  AppBar,
  Toolbar,
  TextField,
  Button,
  Tooltip,
  Grid,
  MenuItem,
  makeStyles,
  withStyles,
  FormHelperText
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import Target from '../assets/images/target.png';
import Dougnut from '../components/Dougnut';
import ArrowDropDownCircleOutlinedIcon from '@material-ui/icons/ArrowDropDownCircleOutlined';
import clsx from 'clsx';

const useStyle = makeStyles({
  root: {
    margin: '20px auto'
  },
  rootHide: {
    margin: '20px auto',
    display: 'none'
  },
  titleBar: {
    boxShadow: 'none'
  },
  title: {
    flexGrow: 1,
    textAlign: 'left',
    textTransform: 'uppercase'
  },
  content: {
    padding: '2rem'
  },
  submit: {
    margin: '3rem auto'
  },
  formError: {
    color: 'red',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  menuItem: {
    textAlign: 'left !important'
  },
  target: {
    height: '85px',
    width: '85px',
    objectFit: 'contain'
  },
  submitBtn: {
    borderRadius: '10px !important'
  },
  dropIcon: {
    color: '#fc9c1c'
  }
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#EDEDED',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.subtitle2,
    border: '1px solid #EDEDED'
  }
}))(Tooltip);

const activityLevelList = [
  { text: 'Sedentary – Little or No Exercise – Desk Job', value: 1.2 },
  {
    text: 'Lightly Active – 5,000 to 7,499 Steps Per Day – Light Intensity Exercise 1-3 days/week',
    value: 1.375
  },
  {
    text: 'Moderately Active – 7,499 to 9999 Steps Per Day – Moderate to Vigorous Intensity Exercise 3-5 days per week',
    value: 1.55
  },
  {
    text: 'Very Active – 10,000 to 12,499 Steps Per Day – Moderate to Vigorous Intensity Exercise 3-7 days per week',
    value: 1.725
  },
  {
    text: 'Extremely Active – More Than 12,500 steps – Physical Job or Athlete Training Multiple Times Per Day',
    value: 2.0
  }
];

let bodyFatList = [];
for (let i = 4; i <= 50; i++) {
  bodyFatList.push(i);
}

const Home = () => {
  const classes = useStyle();
  const [compositionCondition, setCompositionCondition] = useState([
    {
      text: '1.0% weight loss per week',
      value: 1
    },
    { text: '0.7% loss per week', value: 0.7 },
    { text: '0.5% per week', value: 0.5 }
  ]);
  const [bcgTab, setBcgTab] = useState(0);
  const [outputBox, toggleOutputBox] = useState(false);
  const [error, setError] = useState();

  const [targetKCal, setTargetKCal] = useState(0);
  const [weight, setWeight] = useState();
  const [height, setHeight] = useState();
  const [age, setAge] = useState();
  const [sex, setSex] = useState();
  const [bodyFat, setBodyFat] = useState(0);
  const [RMR, setRMR] = useState();
  const [activityLevel, setActivityLevel] = useState();
  const [fat, setFat] = useState(0);
  const [fatPer, setFatPer] = useState(25);
  const [protein, setProtein] = useState(0);
  const [proteinPer, setProteinPer] = useState(0);
  const [carbs, setCarbs] = useState(0);
  const [speed, setSpeed] = useState();
  const [maintananceCalories, setMaintananceCalories] = useState(0);
  const [ffmi, setFfmi] = useState(0);
  const [chartData, setChartData] = useState([
    {
      argument: 'PROTEIN',
      val: 1,
      grams: 0
    },
    {
      argument: 'FAT',
      val: 1,
      grams: 0
    },
    {
      argument: 'CARBS',
      val: 1,
      grams: 0
    }
  ]);

  useEffect(() => {
    if (height && weight && bodyFat) {
      const heightInM = height / 39.37;
      setFfmi(
        (
          ((weight / 2.205) * (1 - bodyFat / 100)) /
          (heightInM * heightInM)
        ).toFixed(2)
      );
    }
  }, [height, weight, bodyFat]);

  useEffect(() => {
    if (RMR && activityLevel) {
      setMaintananceCalories(RMR * activityLevel);
    }
  }, [RMR, activityLevel]);

  useEffect(() => {
    if (sex && bodyFat) {
      if (sex === 'Male') {
        if (bodyFat <= 12) {
          setCompositionCondition([{ text: '0.5% per week', value: 0.5 }]);
        } else {
          setCompositionCondition([
            {
              text: '1.0% weight loss per week',
              value: 1
            },
            { text: '0.7% loss per week', value: 0.7 },
            { text: '0.5% per week', value: 0.5 }
          ]);
        }
      } else {
        if (bodyFat <= 22) {
          setCompositionCondition([{ text: '0.5% per week', value: 0.5 }]);
        } else {
          setCompositionCondition([
            {
              text: '1.0% weight loss per week',
              value: 1
            },
            { text: '0.7% loss per week', value: 0.7 },
            { text: '0.5% per week', value: 0.5 }
          ]);
        }
      }
    }
  }, [sex, bodyFat]);

  useEffect(() => {
    if (speed && weight && (bcgTab === 0 || bcgTab === 1)) {
      setTargetKCal(
        Math.ceil(maintananceCalories - (((weight * speed) / 100) * 3500) / 7)
      );
    }
  }, [weight, speed, bcgTab, maintananceCalories]);

  useEffect(() => {
    if (weight && proteinPer) {
      setProtein(Math.ceil(weight * proteinPer));
    }
  }, [weight, proteinPer]);

  useEffect(() => {
    if (targetKCal && fatPer) {
      setFat(Math.ceil((targetKCal * fatPer) / 900));
    }
  }, [targetKCal, fatPer]);

  useEffect(() => {
    if (targetKCal && fat && protein) {
      setCarbs(Math.ceil((targetKCal - (fat * 9 + protein * 4)) / 4));
    }
  }, [targetKCal, fat, protein]);

  useEffect(() => {
    if (fat && protein && carbs) {
      setChartData((c) => {
        return c.map((item) => {
          if (item.argument === 'CARBS') {
            // item.val = parseInt((carbs * 400) / targetKCal);
            item.val = 100 - parseInt((fat * 900) / targetKCal) - parseInt((protein * 400) / targetKCal);
            item.grams = parseInt(carbs);
          }
          if (item.argument === 'FAT') {
            item.val = parseInt((fat * 900) / targetKCal);
            item.grams = parseInt(fat);
          }
          if (item.argument === 'PROTEIN') {
            item.val = parseInt((protein * 400) / targetKCal);
            item.grams = parseInt(protein);
          }
          return item;
        });
      });
    }
  }, [fat, protein, carbs, targetKCal]);

  useEffect(() => {
    if (bcgTab === 0) {
      setProteinPer(0.8);
    } else if (bcgTab === 1) {
      setProteinPer(1.1);
      // setTargetKCal();
    } else if (bcgTab === 2) {
      setProteinPer(1.1);
      setTargetKCal(maintananceCalories);
    } else if (bcgTab === 3) {
      setProteinPer(0.8);
      setTargetKCal(maintananceCalories + 250);
    }
  }, [bcgTab, maintananceCalories]);

  const outputEl = useRef(null);
  const onSubmit = (e) => {
    e.preventDefault();
    if (targetKCal && carbs && fat && protein) {
      toggleOutputBox(true);
      setError();
      setTimeout(function () {
        outputEl.current.scrollIntoView();
      }, 200);
    } else {
      setError('Fill out all the required fields first');
    }
  };
  return (
    <div>
      <Container className={classes.root}>
        <form onSubmit={onSubmit} autoComplete='off' noValidate>
          <Box className={classes.root} boxShadow={4}>
            <AppBar
              position='static'
              color='primary'
              className={classes.titleBar}
            >
              <Toolbar>
                <Typography variant='h6' className={classes.title}>
                  Basic Client Information
                </Typography>
              </Toolbar>
            </AppBar>
            <Box className={classes.content}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={2}>
                  <TextField
                    name='weight'
                    variant='outlined'
                    size='small'
                    label='Weight (in lbs)'
                    fullWidth
                    type='number'
                    value={weight}
                    onChange={(e) => setWeight(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <TextField
                    name='height'
                    variant='outlined'
                    size='small'
                    label='Height (in Inches)'
                    fullWidth
                    type='number'
                    value={height}
                    onChange={(e) => setHeight(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} lg={1}>
                  <TextField
                    name='age'
                    variant='outlined'
                    size='small'
                    label='Age'
                    fullWidth
                    type='number'
                    value={age}
                    onChange={(e) => setAge(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <TextField
                    name='sex'
                    select
                    size='small'
                    variant='outlined'
                    label='Sex'
                    fullWidth
                    onChange={(e) => setSex(e.target.value)}
                    value={sex ? sex : ''}
                    SelectProps={{
                      IconComponent: ({ className }) => (
                        <ArrowDropDownCircleOutlinedIcon
                          className={clsx(className, classes.dropIcon)}
                          color='primary'
                        />
                      )
                    }}
                  >
                    <MenuItem value='Male'>Male</MenuItem>
                    <MenuItem value='Female'>Female</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} lg={2}>
                  <TextField
                    name='bodyFat'
                    select
                    size='small'
                    variant='outlined'
                    label='Body Fat %'
                    fullWidth
                    onChange={(e) => setBodyFat(e.target.value)}
                    value={bodyFat ? bodyFat : ''}
                    SelectProps={{
                      IconComponent: ({ className }) => (
                        <ArrowDropDownCircleOutlinedIcon
                          className={clsx(className, classes.dropIcon)}
                          color='primary'
                        />
                      )
                    }}
                  >
                    {bodyFatList.map((item, i) => (
                      <MenuItem key={item} value={item}>
                        {parseFloat(item).toFixed(1) + '%'}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={11} lg={2}>
                  <TextField
                    name='ffmi'
                    variant='outlined'
                    size='small'
                    label='FFMI (in kg/m2)'
                    fullWidth
                    type='number'
                    value={ffmi}
                    disabled
                  />
                </Grid>
                <Grid item xs={1}>
                  <HtmlTooltip
                    arrow
                    placement='bottom-end'
                    title={
                      <>
                        <Typography variant='body2'>
                          We recommend that males are above at least 19 and
                          females above at least 16 for long-term physical
                          capacity and health.
                        </Typography>
                      </>
                    }
                  >
                    <InfoIcon color='secondary' fontSize='large' />
                  </HtmlTooltip>
                </Grid>
                <Grid item xs={12} lg={2}>
                  <TextField
                    name='RMR'
                    variant='outlined'
                    size='small'
                    label='RMR'
                    fullWidth
                    type='number'
                    value={RMR}
                    onChange={(e) => setRMR(e.target.value)}
                  />
                </Grid>
                <Grid item xs={10} lg={9}>
                  <TextField
                    name='activityLevel'
                    select
                    size='small'
                    variant='outlined'
                    label='Activity Level'
                    fullWidth
                    onChange={(e) => setActivityLevel(e.target.value)}
                    value={activityLevel ? activityLevel : ''}
                    SelectProps={{
                      IconComponent: ({ className }) => (
                        <ArrowDropDownCircleOutlinedIcon
                          className={clsx(className, classes.dropIcon)}
                          color='primary'
                        />
                      )
                    }}
                  >
                    {activityLevelList.map((item) => (
                      <MenuItem
                        key={item.value}
                        value={item.value}
                        className={classes.menuItem}
                      >
                        {item.text}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={1}>
                  <HtmlTooltip
                    arrow
                    placement='bottom-end'
                    title={
                      <>
                        <Typography variant='body2'>
                          We recommend a minimum moderate level of activity for
                          health and getting you the best results.
                        </Typography>
                      </>
                    }
                  >
                    <InfoIcon color='secondary' fontSize='large' />
                  </HtmlTooltip>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name='maintananceCalories'
                    variant='outlined'
                    label='Maintanance Calories'
                    type='number'
                    value={maintananceCalories}
                    size='small'
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box className={classes.root} boxShadow={4}>
            <AppBar
              position='static'
              color='primary'
              className={classes.titleBar}
            >
              <Toolbar>
                <Typography variant='h6' className={classes.title}>
                  Body Composition Goals
                </Typography>
              </Toolbar>
            </AppBar>
            <Box className={classes.content}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  <TextField
                    name='sex'
                    variant='outlined'
                    label='Sex'
                    type='text'
                    value={sex ? sex : ''}
                    size='small'
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    name='bodyFat'
                    variant='outlined'
                    label='Body Fat (%)'
                    type='text'
                    value={bodyFat ? bodyFat : ''}
                    size='small'
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} justify='center' direction='row'>
                <Grid item xs={6} md={3}>
                  <Button
                    size='large'
                    variant={bcgTab === 0 ? 'contained' : 'outlined'}
                    fullWidth
                    color='secondary'
                    onClick={() => setBcgTab(0)}
                  >
                    Cut
                  </Button>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Button
                    size='large'
                    variant={bcgTab === 1 ? 'contained' : 'outlined'}
                    fullWidth
                    color='secondary'
                    onClick={() => setBcgTab(1)}
                  >
                    Recomp
                  </Button>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Button
                    size='large'
                    variant={bcgTab === 2 ? 'contained' : 'outlined'}
                    fullWidth
                    color='secondary'
                    onClick={() => setBcgTab(2)}
                  >
                    Gaintain
                  </Button>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Button
                    size='large'
                    variant={bcgTab === 3 ? 'contained' : 'outlined'}
                    fullWidth
                    color='secondary'
                    onClick={() => setBcgTab(3)}
                  >
                    Lean Gain
                  </Button>
                </Grid>
                {bcgTab === 0 || bcgTab === 1 ? (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        name='speed'
                        select
                        size='small'
                        variant='outlined'
                        label='Choose rate of Weight Loss'
                        fullWidth
                        onChange={(e) => setSpeed(e.target.value)}
                        value={speed ? speed : ''}
                        SelectProps={{
                          IconComponent: ({ className }) => (
                            <ArrowDropDownCircleOutlinedIcon
                              className={clsx(className, classes.dropIcon)}
                              color='primary'
                            />
                          )
                        }}
                      >
                        {compositionCondition.map((item, i) => (
                          <MenuItem key={item.value} value={item.value}>
                            {item.text}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                <Grid item align='center'>
                  <img
                    src={Target}
                    alt='target'
                    width='85'
                    className={classes.target}
                  />

                  <Typography variant='h6' color='secondary'>
                    {targetKCal ? targetKCal + ' KCals' : ''}
                  </Typography>
                  <Typography variant='overline'>
                    YOUR DAILY CALORIE TARGET IS
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box className={classes.root} boxShadow={4}>
            <AppBar
              position='static'
              color='primary'
              className={classes.titleBar}
            >
              <Toolbar>
                <Typography variant='h6' className={classes.title}>
                  MACRONUTRIENTS
                </Typography>
              </Toolbar>
            </AppBar>
            <Box className={classes.content}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={6} order={{ xs: 1, lg: 1 }}>
                  <TextField
                    name='protein percent'
                    size='small'
                    type='number'
                    variant='outlined'
                    label='Protein (in g/lbs)'
                    fullWidth
                    onChange={(e) => setProteinPer(e.target.value)}
                    value={proteinPer ? proteinPer : ''}
                  />
                </Grid>
                <Grid item xs={12} lg={6} order={{ xs: 3, lg: 2 }}>
                  <TextField
                    name='fat percent'
                    size='small'
                    variant='outlined'
                    type='number'
                    label='Fat %'
                    onChange={(e) => setFatPer(e.target.value)}
                    helperText='*25% is the minimum recommended'
                    value={fatPer}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} lg={6} order={{ xs: 2, lg: 3 }}>
                  <TextField
                    name='protein'
                    size='small'
                    type='number'
                    variant='outlined'
                    label='Protein (in g)'
                    helperText='*minimum amount recommended'
                    fullWidth
                    value={protein ? protein : ''}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} lg={6} order={{ xs: 4, lg: 4 }}>
                  <TextField
                    name='fat'
                    size='small'
                    variant='outlined'
                    type='number'
                    label='Fat (in g)'
                    value={fat ? fat : ''}
                    fullWidth
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3} justify='center'>
                <Grid item xs={12} lg={6}>
                  <Dougnut data={chartData} />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Grid container justify='center' className={classes.submit}>
            <Grid item xs={10} md={3}>
              {error ? (
                <FormHelperText error className={classes.formError} variant="filled">
                  {error}
                </FormHelperText>
              ) : (
                ''
              )}
              <Button
                variant='contained'
                size='large'
                color='secondary'
                className={classes.submitBtn}
                fullWidth
                type='submit'
              >
                Check
              </Button>
            </Grid>
          </Grid>
          <div ref={outputEl}>
            <Box
              className={outputBox ? classes.root : classes.rootHide}
              boxShadow={4}
            >
              <AppBar
                position='static'
                color='secondary'
                className={classes.titleBar}
              >
                <Toolbar>
                  <Typography variant='h6' className={classes.title}>
                    OUTPUT
                  </Typography>
                </Toolbar>
              </AppBar>
              <Box className={classes.content}>
                <Grid container spacing={3} justify='space-between'>
                  <Grid item>
                    <Typography variant='subtitle1' className={classes.title}>
                      Total KCals
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='subtitle2' className={classes.title}>
                      {`${Math.floor(targetKCal / 100) * 100} - ${
                        Math.ceil(targetKCal / 100) * 100
                      } KCal`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3} justify='space-between'>
                  <Grid item>
                    <Typography variant='subtitle1' className={classes.title}>
                      Fat
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='subtitle2' className={classes.title}>
                      {`${Math.floor(fat / 10) * 10} - ${
                        Math.ceil(fat / 10) * 10
                      } grams`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3} justify='space-between'>
                  <Grid item>
                    <Typography variant='subtitle1' className={classes.title}>
                      Protein
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='subtitle2' className={classes.title}>
                      {`${Math.floor(protein / 20) * 20} - ${
                        Math.ceil(protein / 20) * 20
                      } grams`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={3} justify='space-between'>
                  <Grid item>
                    <Typography variant='subtitle1' className={classes.title}>
                      Carbs
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='subtitle2' className={classes.title}>
                      {`${Math.floor(carbs / 20) * 20} - ${
                        Math.ceil(carbs / 20) * 20
                      } grams`}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </div>
        </form>
      </Container>
    </div>
  );
};

export default Home;
