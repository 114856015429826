import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#ffcf53',
      main: '#fc9c1c',
      dark: '#c66f00',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#96ef9b',
      main: '#64bc6c',
      dark: '#318b40',
      contrastText: '#ffffff',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        padding: '14px 22px !important',
        borderRadius: '4px !important',
        boxShadow: '4px 1px 14px rgba(0, 0, 0, 0.0657705); !important',
      },
      outlined: {
        border: '0 !important',
      },
    },
  },
});
ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
