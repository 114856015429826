import React from 'react';
import {
  Chart,
  PieSeries,
  Legend
} from '@devexpress/dx-react-chart-material-ui';
import { Animation, Palette } from '@devexpress/dx-react-chart';
import StopIcon from '@material-ui/icons/Stop';
import { makeStyles, Typography } from '@material-ui/core';

const SpecialMarkerComponent = (data) => {
  return <StopIcon style={{ color: data.color }} />;
};

const renderLabel = (text, data) => {
  const item = data.filter((d) => d.argument === text.text)[0];
  return (
    <>
      <Typography variant='subtitle2'>{text.text}:&nbsp;</Typography>
      <Typography variant='subtitle1'>{item.val}%&nbsp;</Typography>
      <Typography variant='subtitle1'>({item.grams} grams)</Typography>
    </>
  );
};

const useStyle = makeStyles({
  root: {
    height: 'max-content !important',
    minWidth: '250px'
  }
});

const Dougnut = ({ data }) => {
  const classes = useStyle();
  return (
    <div>
      <Chart data={data} className={classes.root}>
        <Palette scheme={['#225677', '#64be6b', '#f19d18']} />
        <PieSeries
          name='Nutrition'
          valueField='val'
          argumentField='argument'
          innerRadius={0.4}
        />
        <Legend
          markerComponent={SpecialMarkerComponent}
          visible={true}
          labelComponent={(text) => renderLabel(text, data)}
        />
        {/* <Tooltip enabled={true} customizeTooltip={renderTooltip} /> */}
        <Animation />
      </Chart>
    </div>
  );
};

export default Dougnut;
