import { AppBar, makeStyles, Toolbar } from "@material-ui/core";
import React from "react";
import { Container } from "@material-ui/core";
import logo from "../assets/images/logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: "center",
  },
  logo: {
    padding: "20px 0",
    height: "50px",
    objectFit: "contain",
    marginRight: "10px",
  },
}));

const Navbar = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent">
        <Container>
          <Toolbar>
            <img src={logo} alt="Kitty Katty!" className={classes.logo} />
            {/* <Typography variant="h6" className={classes.title}>
              Home
            </Typography> */}
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default Navbar;
